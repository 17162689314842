import clsx from "clsx";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  selectSelectFavoritesModel,
  updateFavoritesModel,
} from "../../../features/projectManagement/projectManagementSlice";
import {
  selectShowFlexTasks,
  selectShowHideColumnProjects,
  updateRefinerState,
  updateShowFlexTasks,
  updateShowHideColumn,
} from "../../../features/projectManagementPmr/projectManagementPmrSlice";
import {
  IProjectManagementViewByOption,
  projectManagementViewByOptions,
} from "../../../utils/constants/project-management.constants";
import classes from "./ProjectListHeaderPmr.module.scss";
import ProjectListHeaderActions from "../shared/components/project-list-header-actions/ProjectListHeaderActions";

import Favorites from "../../../shared/favorites/Favorites";
import FavoriteTypesEnum from "../../../shared/favorites/FavoriteTypesEnum";
import { authProvider } from "../../../providers/authProvider";
import ProjectTaskStatus from "../shared/enums/project-task-status";
import ShowHideColumnsMultiplePrimeDataTable from "../../../shared/show-hide-columns-multiple-prime-datatable/ShowHideColumnsMultiplePrimeDataTable";
import { ShowHideColumnsMultipleDataTableModel } from "../../../shared/show-hide-columns-multiple-prime-datatable/interface/ShowHideColumnsMultipleDataTableModel";
import { ProjectShowHideColumnsEnum } from "../shared/enums/project-show-hide-columns";
import {
  GridColumnModel,
  projectListcolumns,
} from "../data/project-grid-columns";
import { PmrShowHideArrayValues } from "../shared/interfaces/pmr-show-hide-array-values";
import { ProjectManagementPmrShowFlexTasks } from "../../../features/projectManagementPmr/prohectManagementPmrSliceInterfaces";
import { projectTaskListColumns } from "../data/project-task-grid-columns";
import { projectOrderLineColumns } from "../data/project-order-line-grid-columns";
import { projectDetailsOrderlneListColumns } from "../data/project-details-orderline-list-grid-columns";
import ProjectStatus from "../shared/enums/project-status";
import { USE_PROJECT_HANDLER_MAPPING } from "../../../utils/constants/feature-flag.constants";
import { toLower } from "lodash";

const defaultColumnsState = [
  {
    key: ProjectShowHideColumnsEnum.Project,
    title: "Project",
    fields: projectListcolumns,
    maxFrozenColumnCount: 4,
    frozenColumnCount: 1,
    hasShowAndFreezeSelection: true,
    displayFrozenColumnDropdown: false,
  },
  {
    key: ProjectShowHideColumnsEnum.Table,
    title: "Table",
    subHeader: "Task",
    fields: projectTaskListColumns,
    maxFrozenColumnCount: 4,
    frozenColumnCount: 2,
    hasShowAndFreezeSelection: true,
    displayFrozenColumnDropdown: false,
  },
  {
    key: ProjectShowHideColumnsEnum.Table,
    title: "Table",
    subHeader: "Order Line",
    fields: projectOrderLineColumns,
    maxFrozenColumnCount: 4,
    frozenColumnCount: 1,
    hasShowAndFreezeSelection: true,
    displayFrozenColumnDropdown: false,
  },
  {
    key: ProjectShowHideColumnsEnum.OrderLine,
    title: "Order Line Details",
    fields: projectDetailsOrderlneListColumns,
    maxFrozenColumnCount: 7,
    frozenColumnCount: 0,
    hasShowAndFreezeSelection: true,
    displayFrozenColumnDropdown: false,
  },
];

const ProjectListHeaderPmr = (props: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);

  const userLoggedIn = authProvider.getAccount();
  const favoritesModel = useSelector(selectSelectFavoritesModel);

  const selectShowHideColumnProject = useSelector(selectShowHideColumnProjects);

  const [showHideColumnData, setShowHideColumnData] = useState<
    Array<ShowHideColumnsMultipleDataTableModel<ProjectShowHideColumnsEnum>>
  >([]);

  const viewByOptions: IProjectManagementViewByOption[] =
    projectManagementViewByOptions;
  const [selectedView, setSelectedView] = useState(
    projectManagementViewByOptions[0]
  );
  const [refinersValue, setRefinersValue] = useState(null);

  const [favoriteTooltip, setFavoriteToolTip] = useState("Favorites");
  const showFlexTaskOnly = useSelector(selectShowFlexTasks);

  useEffect(() => {
    setShowHideColumnData(selectShowHideColumnProject);
  }, [selectShowHideColumnProject]);

  useEffect(() => {
    if (refinersValue) {
      dispatch(
        updateRefinerState({
          value: refinersValue,
        })
      );
      setRefinersValue(null);
    }
    if (showHideColumnData) {
      dispatch(
        updateFavoritesModel({
          property: "columns",
          value: showHideColumnData,
        })
      );
    }
  }, [showFlexTaskOnly, showHideColumnData]);

  const changeView = (props: any) => {
    //todo
  };

  const handlerRefinerOption = USE_PROJECT_HANDLER_MAPPING
    ? {
        label: `${userLoggedIn.name} (${toLower(userLoggedIn.idToken.email)})`,
        value: userLoggedIn.userName,
        displayName: userLoggedIn.name,
      }
    : {
        label: `${userLoggedIn.name} (${toLower(userLoggedIn.idToken.email)})`,
        value: userLoggedIn.name,
      };

  const handleOnSetToDefaultFilter = (props: any) => {
    const showFlexTask: ProjectManagementPmrShowFlexTasks = {
      isOn: true,
      isFromClicked: false,
    };

    const value: any = {
      projects: {
        contributors: [handlerRefinerOption],
        projectStatuses: [ProjectStatus.InProgress, ProjectStatus.OnHold],
      },
      orderLines: null,
      searchString: null,
      recordsPerPage: null,
    };

    const favoritesValue = {
      isFlexTaskOnly: showFlexTask.isOn,
      recordsPerPage: props?.recordsPerPage ?? 50,
      refiners: value,
      columns: defaultColumnsState,
    };

    dispatch(updateFavoritesModel(favoritesValue));
    setRefinersValue(value);
    dispatch(updateShowFlexTasks(showFlexTask));
    dispatch(updateShowHideColumn(defaultColumnsState));
  };

  const handleFavoriteSelect = (props: any) => {
    if (!props) return;

    const showFlexTask: ProjectManagementPmrShowFlexTasks = {
      isOn: props?.isFlexTaskOnly ?? true,
      isFromClicked: false,
    };

    const value: any = {
      projects: null,
      orderLines: null,
      searchString: null,
      recordsPerPage: null,
    };

    if (props?.refiners?.projects) {
      const projectsRefiners = props?.refiners?.projects;
      value["projects"] = projectsRefiners;
    } else {
      value["projects"] = {};
    }

    if (props?.refiners?.orderLines) {
      const orderlineRefiners = props?.refiners?.orderLines;
      value["orderLines"] = orderlineRefiners;
    } else {
      value["orderLines"] = {};
    }

    if (props?.refiners?.searchString) {
      const searchString = props?.refiners?.searchString;
      value["searchString"] = searchString;
    } else {
      value["searchString"] = "";
    }

    dispatch(
      updateFavoritesModel({
        property: "recordsPerPage",
        value: props?.recordsPerPage,
      })
    );

    setRefinersValue(value);
    dispatch(updateShowFlexTasks(showFlexTask));

    const syncedOriginColumns = structuredClone(props.columns as any[]).map(
      (column) => {
        const fieldOrigins: GridColumnModel[] = (() => {
          switch (column.key) {
            case ProjectShowHideColumnsEnum.Project:
              return projectListcolumns;
            case ProjectShowHideColumnsEnum.Table:
              switch (column.subHeader) {
                case "Task":
                  return projectTaskListColumns;
                case "Order Line":
                  return projectOrderLineColumns;
                default:
                  return [];
              }
            case ProjectShowHideColumnsEnum.OrderLine:
              return projectDetailsOrderlneListColumns;
            default:
              return [];
          }
        })();

        return {
          ...column,
          fields: fieldOrigins.map((fieldOrigin) => {
            const favConfig = structuredClone(
              column.fields as GridColumnModel[]
            ).find((fav) => fav.id === fieldOrigin.id);

            if (favConfig) {
              favConfig.value = fieldOrigin.value;
              return favConfig;
            }

            return fieldOrigin;
          }),
        };
      }
    );

    dispatch(
      updateFavoritesModel({
        property: "columns",
        value: syncedOriginColumns,
      })
    );
    dispatch(updateShowHideColumn(syncedOriginColumns));
  };

  const handleFavoriteInit = (props: any) => {
    const isByPass = urlSearch.get("bypass");
    if (!isByPass) {
      if (!props) return;

      const showFlexTask: ProjectManagementPmrShowFlexTasks = {
        isOn: props?.isFlexTaskOnly ?? true,
        isFromClicked: false,
      };

      const value: any = {
        projects: null,
        orderLines: null,
        searchString: null,
        recordsPerPage: null,
      };

      if (props?.refiners?.projects) {
        const projectsRefiners = props?.refiners?.projects;
        value["projects"] = projectsRefiners;
      } else {
        value["projects"] = {};
      }

      if (props?.refiners?.orderLines) {
        const orderlineRefiners = props?.refiners?.orderLines;
        value["orderLines"] = orderlineRefiners;
      } else {
        value["orderLines"] = {};
      }

      if (props?.refiners?.searchString) {
        const searchString = props?.refiners?.searchString;
        value["searchString"] = searchString;
      } else {
        value["searchString"] = "";
      }

      dispatch(
        updateFavoritesModel({
          property: "recordsPerPage",
          value: props?.recordsPerPage,
        })
      );

      setRefinersValue(value);
      dispatch(updateShowFlexTasks(showFlexTask));
      dispatch(
        updateFavoritesModel({
          property: "columns",
          value: props?.columns,
        })
      );
      dispatch(updateShowHideColumn(props?.columns));
    }
  };

  const updateShowHideColumnsFrozenReorder = (
    arrayFields: Array<PmrShowHideArrayValues>
  ) => {
    const newArray = arrayFields.map((item) => {
      return {
        ...item,
        fields: item.fields.map((f: GridColumnModel) => {
          if (f.orderNoShowHide! <= item.frozenCount) {
            return {
              ...f,
              isShown: true,
              isFrozen: true,
            };
          } else {
            return {
              ...f,
              isFrozen: false,
            };
          }
        }),
      };
    });

    dispatch(updateShowHideColumn(newArray));
    dispatch(
      updateFavoritesModel({
        property: "columns",
        value: newArray,
      })
    );
  };

  const updateShowHideColumnsVisbility = (
    arrayFields: Array<PmrShowHideArrayValues>
  ) => {
    dispatch(updateShowHideColumn(arrayFields));
    dispatch(
      updateFavoritesModel({
        property: "columns",
        value: arrayFields,
      })
    );
  };

  return (
    <>
      <div className={`${classes["project-header-container"]}`}>
        <div className={`${classes["left-section"]}`}>
          <div> View by: </div>
          <div>
            <Dropdown
              style={{ width: "200px" }}
              className={`${classes["dropdownViewBy"]}`}
              options={viewByOptions}
              optionLabel="name"
              value={selectedView}
              onChange={changeView}
              dropdownIcon={clsx("material-icons", classes["drop-down-icon"])}
            />
          </div>
          <ProjectListHeaderActions filterByProject={false} project={null} />
        </div>
        <div className={`${classes["right-section"]}`}>
          <div title={favoriteTooltip ? favoriteTooltip : "Favorites"}>
            <Favorites
              favoriteTypesId={
                USE_PROJECT_HANDLER_MAPPING
                  ? FavoriteTypesEnum.AllProjectsPmr
                  : FavoriteTypesEnum.AllProjects
              }
              onFavoriteSelect={handleFavoriteSelect}
              jsonModel={{
                ...favoritesModel,
                isFlexTaskOnly: showFlexTaskOnly.isOn,
              }}
              onFavoriteInit={handleFavoriteInit}
              onSetToDefaultFilter={handleOnSetToDefaultFilter}
              setFavoriteToolTip={setFavoriteToolTip}
            />
          </div>
          <div>
            <button
              type="button"
              className={`ul-button ${classes["btn-icon"]}`}
              title="Expand Rows"
            >
              <ShowHideColumnsMultiplePrimeDataTable
                items={showHideColumnData}
                updateShowHideColumnsFrozenReorder={
                  updateShowHideColumnsFrozenReorder
                }
                updateShowHideColumnsVisibility={updateShowHideColumnsVisbility}
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectListHeaderPmr;
