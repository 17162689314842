import { GridColumnModel } from "./project-grid-columns";
export const projectDetailsOrderlneListColumnCheckbox = "checkbox";
export const projectDetailsOrderlneListColumnAction = "action";
export const projectDetailsOrderlneListColumnFlexTemplate = "flexTemplate";
export const projectDetailsOrderlneListColumnGmawbTemplate = "gmaWBTemplate";
export const projectDetailsOrderlneListColumnStatus = "orderLineStatus";
export const projectDetailsOrderlneListColumnEcd = "orderLineECD";
export const projectDetailsOrderlneListColumnCrcd = "orderLineCRCD";
export const projectDetailsOrderlneListColumnOrderOwner = "cemOrderOwner";
export const projectDetailsOrderlneListColumnPrice = "price";
export const projectDetailsOrderlneListColumnFlexHandler = "flexHandler";
export const projectDetailsOrderlneListColumnGmaWbHandler = "gmaWBHandler";
export const projectDetailsOrderlneListColumnCertificationScheme =
  "certificateScheme";
export const projectDetailsOrderlneListColumnApplicationType =
  "applicationType";
export const projectDetailsOrderlneListColumnAdditionalInfo = "additionalInfo";
export const projectDetailsOrderlneListColumnDeliveryPath = "deliveryPath";
export const projectDetailsOrderlneListColumnLeadTime = "leadTimeWeeks";
export const projectDetailsOrderlneListColumnTechnology = "technologyCsv";
export const projectDetailsOrderlneListColumnUlRemarks = "ulRemarks";
export const projectDetailsOrderlneListColumnCustomerRemarks =
  "customerRemarks";
export const projectDetailsOrderlneListColumns: GridColumnModel[] = [
  {
    id: projectDetailsOrderlneListColumnCheckbox,
    value: "",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: true,
    width: "50px",
    widthNum: 50,
    orderNo: 1,
    isColumnNotReordable: true,
    isFlexWidth: false,
    align: "left",
    orderNoShowHide: 0,
    canHide: false,
  },
  {
    id: projectDetailsOrderlneListColumnAction,
    value: "",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: true,
    width: "50px",
    widthNum: 50,
    orderNo: 2,
    isColumnNotReordable: true,
    isFlexWidth: false,
    align: "left",
    orderNoShowHide: 0,
    canHide: false,
  },
  {
    id: "projectNumber",
    value: "Project Number",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "120px",
    widthNum: 120,
    orderNo: 3,
    isColumnNotReordable: false,
    isFlexWidth: true,
    orderNoShowHide: 1,
    canHide: true,
  },
  {
    id: "orderNumber",
    value: "Order Number",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "120px",
    widthNum: 120,
    orderNo: 4,
    isColumnNotReordable: false,
    isFlexWidth: true,
    orderNoShowHide: 2,
    canHide: true,
  },
  {
    id: "orderLineNumber",
    value: "Order Line Number",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "220px",
    widthNum: 220,
    orderNo: 5,
    isColumnNotReordable: false,
    isFlexWidth: true,
    orderNoShowHide: 3,
    canHide: true,
  },
  {
    id: projectDetailsOrderlneListColumnFlexTemplate,
    value: "FLEX Template",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "170px",
    widthNum: 170,
    orderNo: 6,
    isColumnNotReordable: false,
    isFlexWidth: true,
    minWidth: 150,
    orderNoShowHide: 4,
    canHide: true,
  },
  {
    id: projectDetailsOrderlneListColumnGmawbTemplate,
    value: "GMAWB Template",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "170px",
    widthNum: 170,
    orderNo: 7,
    isColumnNotReordable: false,
    isFlexWidth: true,
    minWidth: 150,
    orderNoShowHide: 5,
    canHide: true,
  },
  {
    id: projectDetailsOrderlneListColumnStatus,
    value: "Order Line Status",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 200,
    orderNo: 8,
    isColumnNotReordable: false,
    isFlexWidth: true,
    minWidth: 150,
    orderNoShowHide: 6,
    canHide: false,
  },
  {
    id: projectDetailsOrderlneListColumnEcd,
    value: "Order Line ECD",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "170px",
    widthNum: 170,
    orderNo: 9,
    isColumnNotReordable: false,
    isFlexWidth: true,
    minWidth: 150,
    orderNoShowHide: 7,
    canHide: false,
  },
  {
    id: projectDetailsOrderlneListColumnCrcd,
    value: "Order Line CRCD",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "170px",
    widthNum: 170,
    orderNo: 10,
    isColumnNotReordable: false,
    isFlexWidth: true,
    minWidth: 150,
    orderNoShowHide: 8,
    canHide: true,
  },
  {
    id: projectDetailsOrderlneListColumnOrderOwner,
    value: "CEM/Order Owner",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "150px",
    widthNum: 150,
    orderNo: 11,
    isColumnNotReordable: false,
    isFlexWidth: true,
    orderNoShowHide: 9,
    canHide: true,
  },
  {
    id: projectDetailsOrderlneListColumnPrice,
    value: "Quotation Price",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "150px",
    widthNum: 150,
    orderNo: 12,
    isColumnNotReordable: false,
    isFlexWidth: true,
    orderNoShowHide: 10,
    canHide: true,
  },
  {
    id: projectDetailsOrderlneListColumnFlexHandler,
    value: "FLEX Handler",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "150px",
    widthNum: 150,
    orderNo: 13,
    isColumnNotReordable: false,
    isFlexWidth: true,
    orderNoShowHide: 11,
    canHide: true,
  },
  {
    id: projectDetailsOrderlneListColumnGmaWbHandler,
    value: "GMAWB Handler",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "150px",
    widthNum: 150,
    orderNo: 14,
    isColumnNotReordable: false,
    isFlexWidth: true,
    orderNoShowHide: 12,
    canHide: true,
  },
  {
    id: "modelNameCsv",
    value: "Model Name",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "150px",
    widthNum: 150,
    orderNo: 15,
    isColumnNotReordable: false,
    isFlexWidth: true,
    orderNoShowHide: 13,
    canHide: true,
  },
  {
    id: "productTypesCsv",
    value: "Product Type",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "150px",
    widthNum: 150,
    orderNo: 16,
    isColumnNotReordable: false,
    isFlexWidth: true,
    orderNoShowHide: 14,
    canHide: true,
  },
  {
    id: "pocCountry",
    value: "PoC Country",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "150px",
    widthNum: 150,
    orderNo: 17,
    isColumnNotReordable: false,
    isFlexWidth: true,
    orderNoShowHide: 15,
    canHide: true,
  },
  {
    id: "pointOfSale",
    value: "Point-of-Sale",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "150px",
    widthNum: 150,
    orderNo: 18,
    isColumnNotReordable: false,
    isFlexWidth: true,
    orderNoShowHide: 16,
    canHide: true,
  },
  {
    id: projectDetailsOrderlneListColumnCertificationScheme,
    value: "Certificate Scheme",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "150px",
    widthNum: 150,
    orderNo: 19,
    isColumnNotReordable: false,
    isFlexWidth: true,
    minWidth: 150,
    orderNoShowHide: 17,
    canHide: true,
  },
  {
    id: projectDetailsOrderlneListColumnApplicationType,
    value: "Application Type",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "150px",
    widthNum: 150,
    orderNo: 20,
    isColumnNotReordable: false,
    isFlexWidth: true,
    minWidth: 150,
    orderNoShowHide: 18,
    canHide: true,
  },
  {
    id: projectDetailsOrderlneListColumnAdditionalInfo,
    value: "Additional Info",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "150px",
    widthNum: 150,
    orderNo: 21,
    isColumnNotReordable: false,
    isFlexWidth: true,
    minWidth: 150,
    orderNoShowHide: 19,
    canHide: true,
  },
  {
    id: projectDetailsOrderlneListColumnDeliveryPath,
    value: "Delivery Path/Agent",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "150px",
    widthNum: 150,
    orderNo: 22,
    isColumnNotReordable: false,
    isFlexWidth: true,
    minWidth: 150,
    orderNoShowHide: 20,
    canHide: true,
  },
  {
    id: projectDetailsOrderlneListColumnLeadTime,
    value: "LT/Weeks",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "150px",
    widthNum: 150,
    orderNo: 23,
    isColumnNotReordable: false,
    isFlexWidth: true,
    minWidth: 150,
    orderNoShowHide: 21,
    canHide: true,
  },
  {
    id: projectDetailsOrderlneListColumnTechnology,
    value: "Technologies",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "150px",
    widthNum: 150,
    orderNo: 24,
    isColumnNotReordable: false,
    isFlexWidth: true,
    minWidth: 150,
    orderNoShowHide: 22,
    canHide: true,
  },
  {
    id: "gmaWBProjectCreator",
    value: "GMAWB Project Creator",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "150px",
    widthNum: 150,
    orderNo: 25,
    isColumnNotReordable: false,
    isFlexWidth: true,
    orderNoShowHide: 23,
    canHide: true,
  },
  {
    id: "flexProjectCreatorName",
    value: "Flex Project Creator",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "150px",
    widthNum: 150,
    orderNo: 26,
    isColumnNotReordable: false,
    isFlexWidth: true,
    orderNoShowHide: 24,
    canHide: true,
  },
  {
    id: projectDetailsOrderlneListColumnUlRemarks,
    value: "UL Remarks",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "150px",
    widthNum: 150,
    orderNo: 27,
    isColumnNotReordable: false,
    isFlexWidth: true,
    minWidth: 150,
    orderNoShowHide: 25,
    canHide: true,
  },
  {
    id: projectDetailsOrderlneListColumnCustomerRemarks,
    value: "Customer Remarks",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "150px",
    widthNum: 150,
    orderNo: 28,
    isColumnNotReordable: false,
    isFlexWidth: true,
    minWidth: 150,
    orderNoShowHide: 26,
    canHide: true,
  },
];
